<template>
    <div class="container" v-cloak>

      <div class="row justify-content-md-center mt-4 mb-2 pb-row">
        <div class="col-12 col-lg-8 text-center login-signup">
          <h4>Log In</h4>
        </div>
      </div>

      <div class="row justify-content-md-center mt-4 mb-2 pb-row">
        <div class="col-12 col-lg-8 text-center pb-5 login-signup-form">
          <div v-if="!isShowingLoginDetails" class="row justify-content-center">
            <div class="preLoginButton imgBtnContainer" @click="isShowingLoginDetails = true;">
               <img src="@/assets/img/email.svg" />
               <span>Login with Email</span>
            </div>         
          </div>
          <div v-if="isShowingLoginDetails" class="row">
            <div class="col-12 col-lg-8 text-left pb-4 mg-auto">
              <form>
                <div class="form-group">
                  <label for="inputEmail1">Email</label>
                  <input type="email" v-model="username" class="form-control" id="inputEmail1" aria-describedby="emailHelp" placeholder="Enter email" autofocus>
                  <!--<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
                </div>

                <div class="form-group">
                  <label for="inputPassword1">Password</label>
                  <input v-on:keyup.enter="loginWithUserPass()" type="password" v-model="password" class="form-control" id="inputPassword1" placeholder="Password">
                  <small style="float:right"><a href="/reset-password">forgot password</a></small>
                </div>

               <p v-if="loginErrorMsg" style="color: #D8000C">
                  {{loginErrorMsg}}
               </p>

                <a href="login" @click="$event.preventDefault(); loginWithUserPass();" class="btn btn-orange btn-lg btn-block login-btn mt-5">Login</a>
              </form>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-12 col-lg-8 text-center mg-auto">
            <hr>
          </div>
          <div class="col-12 col-lg-8 text-center wallet-connect-btn mg-auto">
             <div class="row justify-content-center">
               <div class="preLoginButton imgBtnContainer" @click="$event.preventDefault(); loginWithCryptoWallet();">
                  <img src="@/assets/img/money-bag.svg" />
                  <span href="login">Login With A Wallet</span>
               </div>            
             </div>
          </div>

        </div>

         <div class="row">
            <div class="col-12 col-lg-8 text-center mg-auto">
               <div class="ssoButton imgBtnContainer" onclick="document.location.assign('/accounts/google/login/')">
                  <img src="@/assets/img/logo_googleg_48dp.svg" />
                  <span>Google</span>
               </div>         
               <div class="ssoButton imgBtnContainer" onclick="document.location.assign('/accounts/twitter/login/')">
                  <img src="@/assets/img/twitter-logo.svg" />
                  <span>Twitter</span>
               </div>         
               <div class="ssoButton imgBtnContainer" onclick="document.location.assign('/accounts/apple/login/')">
                  <img src="@/assets/img/Apple_logo_black.svg" />
                  <span>Apple</span>
               </div>         
            </div>
         </div>
      </div>
    </div>

    </div><!--END CONTAINER-->

</template>

<script>
import { convertUtf8ToHex } from "@walletconnect/utils";
import  { hashMessage, getDateAndRandomValueString } from "@/utils/wallet"

import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";

export default {
   name: "LogIn",
   data() {
      return {
         username: "",
         password: "",
         loginErrorMsg: "",
         provider: null,
         library: null,
         web3Modal: null,
         isShowingLoginDetails: false
      };
   },
   mounted() {
      this.$store.commit("setDidPromptPurchaseOptions", false);

      let scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', 'https://accounts.google.com/gsi/client');
      scriptTag.setAttribute("defer", "defer");
      scriptTag.setAttribute("async", "async");
      document.head.appendChild(scriptTag);
   },
   methods: {
      loginWithUserPass() {
         window.scrollTo(0,0);
         this.loginErrorMsg = "";

         if (this.$store.state.loggedIn) {
            this.$store.commit("logout");
         }

         const to_send = {
            username: this.username,
            password: this.password,
         };
         this.axios
            .post("/api/login", to_send, { withCredentials: true })
            .then((response) => {
               console.log(response);

               // store user state
               this.$store.commit("login");
               this.$store.commit("setAccountData", response.data.account_data);

               window.gtag("event", "LoginSuccess");

               let accountData = response.data.account_data;
               let foundAnySubscriptions = false;
               foundAnySubscriptions = accountData.unlimited_subscription_slice_counts.length > 0;

               if (this.$store.state.purchaseSliceCount && !foundAnySubscriptions) {
                  this.$router.push("/payment");
               }
               else {
                  window.location.assign("/");
               }
            })
            .catch((error) => {
               if (error.response && error.response.status) {
                  this.loginErrorMsg = "Incorrect username or password.";
               } else {
                  this.loginErrorMsg = "Something went wrong, please check your credentials and try again.";
               }
               window.gtag("event", "LoginError");
            });
      },
      showMsgBox(title, message) {
         this.$bvModal.msgBoxOk(message, {
            id: "info-modal",
            title: title,
            size: 'sm',
            buttonSize: 'sm',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
         });
      },
      async loginWithCryptoWallet() {
         window.scrollTo(0,0);
         const infuraID = "b125812f07ef44e0b8deb5c13b7ab5f0";

         const providerOptions = {
            walletconnect: {
               package: WalletConnectProvider,
               options: {
                  infuraId: infuraID
               }
            },
         };

         this.$store.commit("setIsReferralAdmin", false);
         this.$store.commit("setIsSiteAdmin", false);
         this.$store.commit("setNeedsEmailConfirm", false);
         this.$store.commit("setIsWallet", true);

         const web3Modal = new Web3Modal({
            cacheProvider: false,
            providerOptions
         });

         this.web3Modal = web3Modal;
         await this.web3Modal.clearCachedProvider();

         try {
            let provider = await web3Modal.connect();
            console.log(provider);

            const library = new ethers.providers.Web3Provider(provider);
            this.library = library;
            console.log(library);

            const accounts = await library.listAccounts();
            console.log(accounts);

            // Add a random number and datestamp to the message
            const msg = `Login to your Xecret.io account. ${getDateAndRandomValueString()}`;
            this.showMsgBox('Pending Request', 'Approve or reject using your wallet...');

            const msgParams = [
               convertUtf8ToHex(msg),
               accounts[0]
            ];
            console.log(msgParams)

            library.provider.request({
               method: "personal_sign",
               params: msgParams
            }).then((result) => {
               console.log(result);

               // get nonce from server
               this.axios
                  .get("/api/nonce", { withCredentials: true })
                  .then((response) => {
                     console.log(response.data.nonce);

                     const to_send = {
                        nonce: response.data.nonce,
                        address: accounts[0],
                        signature: result,
                        token: hashMessage(msg),
                     };
                     console.log(to_send);
                     this.$store.commit("setDidPromptPurchaseOptions", false);
                     this.axios
                        .post("/api/login", to_send, { withCredentials: true })
                        .then((response) => {
                           console.log(response);
                           // send wallet data with the nonce
                           this.axios
                              .post("/api/login", to_send, { withCredentials: true })
                              .then((response) => {
                                 console.log(response);

                                 this.$bvModal.hide("info-modal");
                                 // store user state
                                 this.$store.commit("login");
                                 this.$store.commit("setAccountData", response.data.account_data);

                                 window.location.assign("/");
                              })
                              .catch((error) => {
                                 this.$bvModal.hide("info-modal");
                                 this.showMsgBox('Pending Request', 'Request canceled.');

                                 if (error.response) {
                                    this.loginErrorMsg = "Something went wrong, please try again.";
                                 }
                              });
                        })
                        .catch(() => {
                           // create account as it does not exist
                           this.axios
                              .post("/api/wallet-signup", to_send, { withCredentials: true })
                              .then((response) => {
                                 console.log(response);
                                 // auto login

                                 this.$store.commit("login");
                                 this.$store.commit("setAccountData", response.data.account_data);

                                 this.$router.push("/");
                              })
                              .catch((error) => {
                                 this.$bvModal.hide("info-modal");
                                 this.showMsgBox('Pending Request', 'Request canceled.');

                                 if (error.response) {
                                    this.loginErrorMsg = "Something went wrong, please try again.";
                                 }
                              });
                           })
                     })
                     .catch((error) => {
                        if (error.response) {
                           this.loginErrorMsg = "Something went wrong, please try again.";
                        }
                     });
            })
            .catch((error) => {
               console.log("this error happened");
               console.error(error);

               this.$bvModal.hide("info-modal");
               this.showMsgBox('Pending Request', 'Request canceled.');
            });


            provider.on("connect", (chainId) => {
               console.log("connect");
               console.log(chainId);
            });


            // Subscribe to accounts change
            provider.on("accountsChanged", (accounts) => {
               console.log("accounts changed");

               // Get updated accounts and chainId
               if (this.$store.state.loggedIn) {
                  console.warn("changed wallet address");
                  if (accounts[0] !== this.$store.username) {
                     this.axios
                        .post("/api/logout", {}, { withCredentials: true })
                        .then((response) => {
                           console.log(response);
                           this.$store.commit('logout');
                        })
                        .catch((error) => {
                           console.log(error);
                        });

                     this.$router.push({name: "Login"});
                  }
               }

            });

            // Subscribe to chainId change
            provider.on("chainChanged", (chainId) => {
               console.log("chain changed");
               console.log(chainId);
               // ignore for now
            });


            // Subscribe to session disconnection
            provider.on("disconnect", (code, reason) => {
               console.log("disconnect");
               console.log(code, reason);
            });

            this.provider = provider;
         }
         catch(e) {
            console.error(e);
         }

      },
   },
};
</script>
